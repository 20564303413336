<template>
    <div class="h-90 ml-2 mr-2">
        <div class="row align-items-center justify-content-center" v-if="ajax_call_in_progress">
            <div class="col-lg-12 py-5 px-3 mt-3">
                <div class="d-flex align-items-center justify-content-center">
                    <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                </div>
            </div>
        </div>
        <div v-else class="pb-10 h-100 bottom-padding">
            <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
                <div class="breadcrumbactive">
                    <div >
                        <span class="pointer" @click="goToCompanyList">Company</span>
                        <span class="pointer" @click="goToListView">Business Unit</span>
                        <div v-if="$route.path.startsWith('/businessunit/create/cmpid')">Create New</div>
                        <div v-else>Edit</div>
                    </div>
                </div>
            </div>
            <!-- <div class="d-flex align-items-center" >
                <div class="d-flex align-items-center" >
                    <div class="header-slant-tab">
                        <span class="text-secondary fs-14" v-if="$route.path.startsWith('/businessunit/create/cmpid')">Create New</span>
                        <span class="text-secondary fs-14" v-else>Edit</span>
                    </div>
                    <div class="header-slant-tab-border"></div>
                </div>
            </div> -->
            <div class="create-company-container w-100 h-100">
                <div class="text-center fw-600 mb-3">
                    <span class="text-secondary fs-16" v-if="$route.path.startsWith('/businessunit/create')">Create New</span>
                    <span class="text-secondary fs-16" v-else>Edit</span>
                </div>
                <div class="create-company-header d-flex align-items-center justify-content-between">
                    <span class="fs-14 text-white pl-4">Business Unit Information</span>
                    <span class="pointer" @click="showHideContent">
                        <i class="text-white" :class="{'icon-chevron-double-up':is_show_hide, 'icon-chevron-double-down':!is_show_hide}" style="font-size: 20px;"></i>
                    </span>
                </div>
                <div v-if="is_show_hide" class="px-6">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 mt-6">
                            <div class="w-100">
                                <label class="input-label">Company Name<span class="pl-1 text-danger">*</span></label>
                                <div class="d-flex align-items-center">
                                    <input type="text" maxlength="100" class="input-field input-box" v-model="company_name" :readonly="true" :disabled="true" placeholder="Enter Company Name" name="company_name" />
                                    <span class="pointer" >
                                        <i class="icon icon-check-circle fs-30 mt-9 ml-2" style="color: rgb(18, 136, 7);"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 mt-6">
                            <div class="w-100">
                                <label class="input-label">Business Unit Name<span class="pl-1 text-danger">*</span></label>
                                <div class="d-flex align-items-center">
                                    <input type="text" maxlength="100" class="input-field input-box" v-validate="'required'" v-model="business_unit_name" name="business_unit_name" placeholder="Enter Business Unit Name" />
                                </div>
                                <!-- <div class="text-right" style="position: absolute;right: 15px;">
                                    <span class="input-field-length">{{ business_unit_name.length }}/100</span>
                                </div> -->
                                <span class="invalid-feedback-form text-danger" v-show="errors.has('business_unit_name')">
                                    {{errors.first('business_unit_name')}}
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 mt-6">
                            <div class="w-100">
                                <label class="input-label">Country<span class="pl-1 text-danger">*</span></label>
                                <div class="d-flex align-items-center">
                                    <multiselect class="diginew-multiselect multiselect-height-issue-fix" v-validate="'required'" name="country" :show-labels="false" :searchable="true" :options="country_options" v-model="country" placeholder="Select Country">
                                        <template slot="noOptions">
                                            <div class="text-secondary text-center fw-600">
                                                List is Empty
                                            </div>
                                        </template>
                                        <template slot="noResult">
                                            <div class="text-secondary text-center fw-600">
                                                No Results Found
                                            </div>
                                        </template>
                                    </multiselect>
                                    <span class="pointer" >
                                        <el-tooltip class="item" effect="dark" content="The value is auto-filled and configurable in Settings. Click here to go to Settings" placement="top">
                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/settings-blue.png" class="ml-2 v-hidden" style="width:25px" alt="">
                                        </el-tooltip>
                                    </span>
                                </div>
                                <span class="invalid-feedback-form text-danger" v-show="errors.has('country')">
                                    {{errors.first('country')}}
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="row">
                                <div class="col-lg-5 col-md-6 col-sm-4 mt-6">
                                    <div class="w-100">
                                        <span class="input-label">Started On (DD/MM/YYYY)<span class="pl-1 text-danger">*</span></span>
                                        <div class="d-flex align-items-center mt-2">
                                            <date-picker class="profile-date-picker calendar-edit-per input-field pt-0 pb-0 pr-0 date-picker-padding-issue-fix" v-model="started_on" value-type="format" lang="en" :not-after="new Date()" type="date" 
                                            :clearable="false"
                                            :editable="false" style="padding-left: 0px !important;"
                                            format="DD/MM/YYYY"></date-picker>
                                        </div>
                                        <span class="invalid-feedback-form text-danger" v-if="is_started_on">
                                            Started on is required.
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-7 col-md-6 col-sm-8 mt-6">
                                    <div class="w-100 position-relative">
                                        <span class="input-label">Business Unit Code<span class="pl-1 text-danger">*</span></span>
                                        <div class="d-flex align-items-center mt-2">
                                            <input type="text" class="input-field input-box" v-validate="'required'" maxlength="50" v-model="business_unit_code" placeholder="Enter Business Unit Code" name="business_unit_code" />
                                        </div>
                                        <!-- <div class="text-right" style="position: absolute;right: 15px;">
                                            <span class="input-field-length">{{ (business_unit_code).length }}/50</span>
                                        </div> -->
                                        <span class="invalid-feedback-form text-danger" v-show="errors.has('business_unit_code')">
                                            {{errors.first('business_unit_code')}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 mt-6">
                            <div class="w-100">
                                <label class="input-label">Description</label>
                                <textarea rows="5" type="text" maxlength="4000" class="input-field input-textarea" v-model="description" placeholder="Enter text here" />
                                <div class="text-right d-flex align-items-start justify-content-end">
                                    <span class="input-field-length">{{ description.length }}/4000</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-10">
                        <div class="d-flex align-items-center justify-content-center">
                            <button class="btn btn-outline-secondary" :disabled="upload_in_progress || save_api_call_in_progress" @click="cancel">Cancel</button>
                            <button class="btn btn-new-success ml-5" :disabled="upload_in_progress || save_api_call_in_progress || fields_not_changed" @click="addBusinessUnit" style="width: 120px;border-radius: 5px;">Save
                                <hollow-dots-spinner v-if="upload_in_progress || save_api_call_in_progress" style="position: absolute;width: 160px !important;margin: 0;top: 12px;left: -15px;display: flex;justify-content: center;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <create-success v-if="create_success" @hideSuccess="hideSuccess" :success_message="success_message" modal_name="create-success"></create-success>
    </div>
</template>
<script>
import { HollowDotsSpinner } from "epic-spinners";
import countries from '../json/countries.json';
import {HalfCircleSpinner} from 'epic-spinners';
import companies from '../mixins/companies';
import CreateSuccess from '../Companies/CreateSuccess.vue';
export default {
    components: { HalfCircleSpinner, CreateSuccess, HollowDotsSpinner },
    mixins: [companies],
    data() {
        return {
            create_success: false,
            success_message: '',
            ajax_call_in_progress: false,
            country_options: countries,
            company_name: '',
            business_unit_name: '',
            country: '',
            started_on: '',
            business_unit_code: '',
            description: '',
            success_message: '',
            is_started_on: false,
            is_show_hide: true,
            upload_in_progress: false,
            save_api_call_in_progress: false,
            stringified_response: "",
            left_tab: false,
        }
    },
    beforeRouteLeave (to, from, next) {
        if (this.left_tab == true) {
                let message = "<img src='/static/images/exclamation-yellow.svg' style='width: 55px;margin-bottom: 15px;' /><br><div class='d-block'>Unsaved changes would be lost, Do you want to proceed?</div>";
                let options = {
                html: true,
                loader: false,
                reverse: false, // switch the button positions (left to right, and vise versa)
                okText: 'Yes',
                cancelText: 'No',
                animation: 'fade',
                type: 'basic',
                verification: 'continue', // for hard confirm, user will be prompted to type this to enable the proceed button
                verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
                backdropClose: true,
            };
            this.$dialog.confirm(message, options)
			.then(function () {
				next();
			})
			.catch(function () {
				next(false);
			});
        } else {
            next()
        }
    },
    methods: {
        cancel() {
            this.$router.push('/businessunit/listview/cmpid/' + this.$route.params.company_id);
        },
        hideSuccess() {
            this.left_tab = false
            this.create_success = false;
            setTimeout(() => {
                this.$router.push('/businessunit/listview/cmpid/' + this.$route.params.company_id);
            }, 500)
        },
        goToCompanyList() {
            this.$router.push('/company/view/cmpid/' + this.$route.params.company_id);
        },
        goToListView() {
            this.$router.push('/businessunit/listview/cmpid/' + this.$route.params.company_id);
        },
        showHideContent() {
            this.is_show_hide = !this.is_show_hide;
        },
        async getCompanyInfo(id) {
            this.ajax_call_in_progress = true;
            try {
                let response = await this.getCompanyById(id);
                if(response.status_id == 1) {
                    this.company_name = response.response.name;
                }
                this.ajax_call_in_progress = false;
            }
            catch(err) {
                console.log(err);
            }
        },
        async confirmAddBusinessUnit() {
            this.upload_in_progress = true;
            try {
                if(this.$route.path.startsWith('/businessunit/edit/cmpid/')) {
                    let response = await this.updateBusinessUnitById(this.create_data, this.$route.params.id);
                    if(response.status_id == 1) {
                        this.success_message = response.message;
                        this.create_success = true;
                        this.left_tab = false
                        setTimeout(() => {
                            this.$modal.show('create-success');
                        }, 500);
                    }
                } else {
                    let response = await this.createBusinessUnit(this.create_data);
                    if(response.status_id == 1) {
                        this.success_message = response.message;
                        this.create_success = true;
                        this.left_tab = false
                        setTimeout(() => {
                            this.$modal.show('create-success');
                        }, 500);
                    }
                }
            }
            catch(err) {
                console.log(err);
                this.upload_in_progress = false;
            }
        },
        addBusinessUnit() {
            this.$validator.validateAll().then(result => {
                if (result == true && this.started_on != '') {
                    this.is_started_on = false;
                    this.confirmAddBusinessUnit();
                } else {
                    if(this.started_on == '') {
                        this.is_started_on = true;
                    }
                }
            })
        },
        async getBusinessUnitData(id) {
            this.ajax_call_in_progress = true;
            try {
                let response = await this.getBusinessUnitById(id);
                if(response.status_id == 1) {
                    let bus_edit_data = response.response;
                    this.business_unit_name = bus_edit_data.name;
                    this.country = bus_edit_data.country;
                    this.started_on = bus_edit_data.started_on ? bus_edit_data.started_on.split("-")[2]+"/"+bus_edit_data.started_on.split("-")[1]+"/"+bus_edit_data.started_on.split("-")[0] : "";
                    this.business_unit_code = bus_edit_data.code;
                    this.description = bus_edit_data.description;
                }
                this.ajax_call_in_progress = false;
                this.stringified_response = JSON.stringify(this.create_data)
            }
            catch(err) {
                console.log(err);
            }
        }

    },
    mounted() {
        if(this.$route.params.company_id) {
            this.getCompanyInfo(this.$route.params.company_id);
        }
        if(this.$route.path.startsWith('/businessunit/edit/cmpid/')) {
            this.getBusinessUnitData(this.$route.params.id);
        }else{
            this.stringified_response = JSON.stringify(this.create_data)
        }
    },
    computed: {
        fields_not_changed(){
            if(this.stringified_response == JSON.stringify(this.create_data)){
                this.left_tab = false
            }else{
                this.left_tab = true
            }
            return this.stringified_response == JSON.stringify(this.create_data)
        },
        loggedInUser() {
            return this.$store.getters.loggedInUser;
        },
        create_data() {
            return {
                name: this.business_unit_name,
                country: this.country,
                started_on: this.started_on ? this.started_on.split("/")[2]+"-"+this.started_on.split("/")[1]+"-"+this.started_on.split("/")[0] : "",
                code: this.business_unit_code,
                description: this.description,
                company_id: this.$route.params.company_id
            }
        }
    },
    watch: {
        started_on(value) {
            if(value) {
                this.is_started_on = false;
            }
        }
    },
    created() {
        const dict = {
            custom: {
                business_unit_name: {
                    required:() => "Business unit name is required",
                },
                country: {
                    required:() => "Country is required",
                },
                business_unit_code: {
                    required:() => "Business unit code is required",
                },
            }
        }
        this.$validator.localize('en', dict)
    }
}
</script>
<style scoped>
    .create-company-container {
        background-color: #fff;
        padding: 15.5px 0 50px 0;
        border-radius: 6px;
    }
    .overflow-auto {
        overflow: auto;
    }
    .create-company-header {
        padding: 9.9px 15px;
        background-color: #00448b;
    }
    @media only screen and (max-width: 768px){
        .bottom-padding{
            padding-bottom: 150px !important;
            margin-right: 5px !important;
        }
    }
    .input-box {
        height: 38px !important;
    }
    .btn-outline-secondary {
        color: #e82828;
        border: 1px solid #e82828 !important;
        width: 120px;
        border-radius: 5px;
    }
    .btn-outline-secondary:hover {
        color: #fff;
        background-color: #e82828;
        border-color: #e82828 !important;
    }
    .input-label{
        padding: 0 0 4px 10px !important;
    }
    .btn-new-success:disabled{
        cursor: no-drop !important;
    }
    .btn:disabled{
        pointer-events: auto !important
    }
</style>